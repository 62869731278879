<template>
  <div class="payrecord">
    <top-bar title="户号信息" hasBack></top-bar>

    <!-- 头像 用户名 -->
    <section class="pay-info">
      <div class="pay02"><p>户名：<span style=" padding-left: 33px;">{{ data.guestName }}</span></p></div>
      <div class="pay02"><p>户号：<span style=" padding-left: 33px;">{{ data.guestNo }}</span></p></div>
      <div class="pay02"><p>地址：<span style=" padding-left: 33px;">{{ data.location }}</span></p></div>
      <div class="pay02"><p>表编号：<span style=" padding-left: 14px;">{{ data.meterAddr }}</span></p></div>
      <div class="pay02"><p>可用余额：<span style=" padding-left: 0px;">{{ data.balance }}</span></p></div>
    </section>

  </div>
</template>

<script>
import TopBar from '@/components/TopBar'
import ajax from '@/api'

export default {
  name: 'guestNoInfo',
  inject: ['reload'],
  components: {TopBar},
  data() {
    return {
      data: {},
      bindId: this.$route.query.bindId
    }
  },
  watch: {},
  created() {
    ajax.getAccountDetail({
      bindId: this.bindId,
    }).then((res) => {
      this.data = res.data || {}
    })
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
@import "style";
</style>
